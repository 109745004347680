@tailwind base;
@tailwind components;
@tailwind utilities;

/* @keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-400px * 5 - 0.5rem * 10));
		transform: translateX(-590vw);
	}
} */

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-1180vw);
    }
}

/* .animate-scroll {
	animation: scroll 60s linear infinite;
} */

.animate-scroll {
    animation: scroll 120s linear infinite;
}